$assets-path: '../../assets' !default;

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@mixin themed(
    $property,
    $properties: ('light', 'dark'),
    $dark-mode-selector: '.dark-mode'
) {
    @if (map-get($properties, light)) {
        #{$property}: map-get($properties, light);
    }

    @if (map-get($properties, dark)) {
        @at-root #{$dark-mode-selector} & {
            #{$property}: map-get($properties, dark);
        }
    }
}

@mixin flex(
    $align: stretch,
    $justify: space-between,
    $wrap: nowrap,
    $direction: row,
    $display: flex
) {
    display: $display;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

@function v($var, $fallback: null) {
    @if ($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

$box-shadow: 0.125rem 0.25rem 1.5rem rgba(0, 0, 0, 0.25);

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if ($index) {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

@function svg-to-data-uri($svg, $fill: '#EA1C0A', $stroke: '#1EA2B1') {
    $svg: str-replace($svg, '#EA1C0A', $fill);
    $svg: str-replace($svg, '#1EA2B1', $stroke);
    $svg: str-replace($svg, '<', '%3C');
    $svg: str-replace($svg, '>', '%3E');
    $svg: str-replace($svg, '"', "'");
    $svg: str-replace($svg, '#', '%23');
    $svg: str-replace($svg, '&', '%26');

    @return url('data:image/svg+xml,#{$svg}');
}

@function svg-url($svg) {
    @if not str-index($svg, xmlns) {
        $svg: str-replace(
            $svg,
            '<svg',
            '<svg xmlns="http://www.w3.org/2000/svg"'
        );
    }

    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);

        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, '%', '%25');
        $chunk: str-replace($chunk, '#', '%23');
        $chunk: str-replace($chunk, '{', '%7B');
        $chunk: str-replace($chunk, '}', '%7D');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return url('data:image/svg+xml,#{$encoded}');
}

@mixin background-svg($svg) {
    background-image: svg-url($svg);
}

@function capitalize-color($color) {
    @return to-upper-case(inspect($color));
}

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@keyframes pulse-circle {
    0% {
        opacity: 1;
        transform: scale(0, 0);
    }

    100% {
        opacity: 0;
        transform: scale(1, 1);
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes spin {
    100% {
        transform: rotate(-360deg);
    }
}

@mixin graph-slider-adjusted(
    $slider-height: 9.5rem,
    $thumb-height: 13rem,
    $thumb-offset: -5.5rem,
    $tooltip-offset: -0.75rem
) {
    @media (min-device-width: 48rem) {
        .c-graph-slider {
            height: $slider-height;

            input[type='range'] {
                &::-webkit-slider-thumb {
                    height: $thumb-height;
                    margin-top: $thumb-offset;
                }

                &::-moz-range-thumb {
                    height: $thumb-height;
                    margin-top: $thumb-offset;
                }

                &::-ms-thumb {
                    height: $thumb-height;
                    margin-top: $thumb-offset;
                }
            }
        }

        .c-graph-tooltip {
            top: $tooltip-offset;
        }
    }
}

@mixin container() {
    @include themed(
        'background-color',
        (
            light: v(color-white),
            dark: v(color-darker),
        )
    );
    @include app-border-radius;
    @include app-box-shadow;

    @include mobile {
        @include app-border-radius(0);
    }
}

@mixin app-spacing($type: 'padding', $spacing: 1.5rem) {
    @if ($type == 'both') {
        padding: #{$spacing};
        margin: #{$spacing};
    } @else {
        #{$type}: $spacing;
    }
}

@mixin app-border-radius($radius: 0.5rem) {
    border-radius: $radius;
}

@mixin app-blur($value: 0.1875rem) {
    filter: blur($value);
}

@mixin large-desktop {
    @media (min-width: 95.625rem) {
        @content;
    }
}

@mixin medium-desktop {
    @media (min-width: 90rem) {
        @content;
    }
}

@mixin mobile {
    @media (max-device-width: 47.9375rem) {
        @content;
    }
}

@mixin app-box-shadow($shadow: $box-shadow) {
    box-shadow: $shadow;
}

@mixin mobile-graph($should-adjust: false) {
    @include mobile {
        .c-frame {
            border: 0;

            .c-frame-content {
                position: relative;
                padding: 1rem;
            }
        }

        @if $should-adjust {
            .c-day-picker {
                &,
                &-selects {
                    margin-bottom: 0.625rem;
                }

                &--week {
                    padding-top: 0.375rem;
                    margin-bottom: 0.875rem;
                }
            }

            .c-history-graph {
                &__prev,
                &__next {
                    top: 1rem;
                }

                &__loader {
                    margin: 2.0625rem 0 !important;
                }
            }

            .c-graph-slider input[type='range']::-webkit-slider-thumb {
                height: 10rem;
                margin-top: -4.75rem;
            }
        }
    }
}

@mixin insights-slider-controls {
    .slick-arrow {
        height: 1.75rem;
        margin-top: -0.875rem;

        .svg-icon {
            stroke: v(color-white);
        }

        &.slick-disabled {
            .svg-icon {
                fill: v(color-lightgrey);
                stroke: v(color-grey);
            }
        }
    }

    .slick-prev {
        left: -0.5625rem;

        .svg-icon {
            transform: rotate(180deg);
        }
    }

    .slick-next {
        right: -0.5625rem;
    }

    .slick-slider {
        margin-bottom: 1rem;
    }

    .slick-dots {
        top: 100%;
        bottom: auto;
        padding: 0;
        margin-top: 1rem;

        li {
            padding: 0 0.1875rem;

            &.slick-active {
                button {
                    background: v(color-secondary);
                }
            }
        }

        button {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.125rem;
        }
    }
}

@mixin app-transition($properties, $timing: v(timing), $easing: v(easing)) {
    transition-property: unquote($properties);
    transition-duration: $timing;
    transition-timing-function: $easing;
    will-change: unquote($properties);
}

@mixin app-link($color: v(color-primary)) {
    color: $color;
    font-weight: bold;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}

@mixin app-arrow-link($color: v(color-primary)) {
    color: $color;
    font-weight: 700;
    letter-spacing: -0.01em;
    @include flex(center, flex-start, nowrap, row, inline-flex);
    padding: 0;
    border: 0;
    background: none;
    border-radius: 0;
    box-shadow: none;
    appearance: none;
    cursor: pointer;

    .svg-icon {
        display: block;
        margin-right: 0.25rem;
        stroke: currentColor;
        stroke-width: 0.125rem;

        &:last-child {
            margin-right: 0;
            margin-left: 0.25rem;
        }
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
}

@mixin widget-border {
    border-width: 0.0625rem;
    border-style: solid;
    @include themed(
        'border-color',
        (
            light: v(color-lightgrey),
            dark: v(color-middlegrey),
        )
    );
}

.c-prompt {
    $prompt: &;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 4.6875rem 0 0 6.25rem;
    width: 100%;
    height: 100%;

    .c-close {
        z-index: 2;
    }

    @include medium-desktop {
        padding-left: 12.5rem;
    }

    &__container {
        height: calc(100% - 3rem);
        @include app-border-radius;
        @include app-spacing('margin');
        @include themed(
            'background-color',
            (
                light: v(color-white),
                dark: v(color-darker),
            )
        );

        @include large-desktop {
            max-width: 80rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__header {
        width: 100%;
    }

    &__title {
        font-size: 1.5rem;
        color: v(color-base);
        font-weight: 600;
        margin: 0 0 var(--prompt-body-spacing) 0;
    }

    &__body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        @include flex(center, flex-start, nowrap, column);
        @include app-spacing;
        height: 100%;
    }

    &__text {
        position: relative;
        text-align: center;
        width: 100%;
        margin-top: auto;

        &--alignleft {
            text-align: left;
        }

        h3 {
            font-size: 1.5rem;
            color: v(color-base);
            font-weight: 400;
        }

        ol {
            list-style-position: inside;
            margin: 0;
            padding: 0;

            li + li {
                margin-top: 1rem;
            }
        }

        .is--error {
            color: v(color-bordeaux);
        }

        a:not([class]) {
            @include app-link;
        }
    }

    &__actions {
        text-align: center;
        width: 100%;
        padding: 3rem 0 2rem;
        margin-bottom: auto;

        .c-btn {
            font-size: 1.125rem;
            margin: 0 0.75rem;
        }

        p {
            font-size: 0.875rem;
            color: v(color-base);
            font-weight: 400;
            margin: 0.5rem 0.75rem 0;
        }

        &:empty {
            padding: 0;
        }
    }

    &--loading {
        .c-btn {
            opacity: 0.7;
            pointer-events: none;
        }
    }

    &--small,
    &--medium {
        background-color: rgba(39, 39, 39, 0.45);

        #{$prompt}__body {
            --prompt-body-spacing: 2rem;
            color: v(color-base);
            width: 30rem;
            height: auto;
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
            padding: var(--prompt-body-spacing);
            margin: auto;
            border-radius: 0.75rem;
            box-shadow: 0 0.3125rem 0.875rem rgba(0, 0, 0, 0.05);

            @include themed(
                'background-color',
                (
                    light: v(color-white),
                    dark: v(color-darkest),
                )
            );

            @include mobile {
                --prompt-body-spacing: 1rem;
            }
        }

        #{$prompt}__container {
            background-color: transparent;

            > div > div:first-child {
                @include flex(center, center);
            }
        }
    }

    &--medium {
        #{$prompt}__body {
            width: 60rem;

            @include mobile {
                width: 100%;
            }
        }
    }

    &--fullwidth {
        #{$prompt}__text {
            text-align: left;
        }
    }

    &--overlaying {
        background-color: transparent;
    }

    &.is--vertical-centered {
        .c-prompt__text {
            margin: auto;
        }
    }

    @include mobile {
        padding: 0;

        &__body {
            padding: 1rem;
        }

        &__container {
            height: 100%;
            margin: 0;
            border-radius: 0;
        }

        &--small {
            #{$prompt}__body {
                width: calc(100% - 2rem);
            }
        }

        .c-close {
            top: 0;
            right: 0;
        }
    }
}
